/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import {
  Article,
  Hero,
  Statement,
  ValuesCollection,
} from "../../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../../layouts";
import socialHead2 from "../../../images/linkedBaner.png";
// import socialHead2 from "../../../images/Banner-Naccho.jpg";
import inline from "../../../images/cure-inline.jpg";
import leftIcon from "../../../images/Left Button.svg";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="PROJECT C.U.R.E – HANDS ON NASHVILLE  | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="PROJECT C.U.R.E – HANDS ON NASHVILLE "
        video=""
        image={Image}
        short={true}
        headings=""
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "Project C.U.R.E. is a benevolent healthcare foundation that collects, categorises, and distributes medical supplies to healthcare professionals and facilities around the world. Donations are collected from hospitals and other healthcare providers, then prepared and shipped internationally to people in need. ",
              },
            ],
          },
        ]}
        id={""}
        className=""
        Frompage="caseStudy"
      />
      <ValuesCollection
        key=""
        title="Organisational purpose and mission statement "
        heading={[
          {
            children: [
              {
                text: "Project C.U.R.E. was founded in 1987 to address the staggering shortage of medical resources around the world. It has become the world’s largest distributor of donated medical supplies, equipment and services to doctors and nurses serving the sick and dying in more than 135 countries. ",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "They are focused on the prevention, diagnosis, and treatment of diseases such as HIV/AIDS, tuberculosis, malaria, Ebola, and COVID-19. They also work alongside health experts to treat victims of earthquakes, floods, and other natural disasters as well as societal crises. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        butlletdesign="Need"
        // statements={[
        //   {
        //     id: "",
        //     title: "SolarBuddy works towards two goals: ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "1. Gift solar devices to children living in extreme poverty",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "2. Educate and inspire people to be agents of change to end extreme energy poverty by 2030 ",
        //     heading: [],
        //   },
        // ]}
      />
      {/* <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        detailsRTF={[
          {
            children: [
              {
                text: "An open‑pit gold miner had just completed a transition to owner‑mining, and faced many operational challenges including old mining equipment and a high‑maintenance processing plant.",
              },
            ],
            type: "heading-three",
          },
          {
            children: [
              {
                text: "Unit costs were under severe pressure: fixed costs increased by 100% following the transition; rising fuel and power costs; main pit getting deeper and haul distances longer.",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "Survey data indicated insufficient digital readiness: approximately a quarter of members do not have nbn access and rural and remote Indigenous communities experience pronounced effects of reduced connectivity and reduced access to technology and digital skills, impeding access to quality healthcare. ",
            heading: [],
          },
          {
            id: "",
            title:
              "A compelling business case was needed to secure the Federal government funding required to improve the connectivity and digital literacy for NACCHO members to facilitate telehealth options. ",
            heading: [],
          },
        ]}
        heading={[
          {
            children: [
              {
                text: "Telehealth can deliver significant benefits including increased access to remote communities, extended capacity and reach of community-based care, and increased efficiency and decreased travel time for patients and providers. However, reliable connectivity, and digital literacy and capability is critical to ensuring successful delivery and uptake of Telehealth services. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        Articletitle="Challenge faced by organisation "
      /> */}
      <ValuesCollection
        key=""
        title="What we did  "
        className="bg-light-blue pt-5"
        changeDesign="YES"
        butlletdesign="YES"
        styleclass="pt-8px"
        statements={[
          {
            id: "",
            title:
              "Volunteered 35 team members and served over 105 hours to help",
            heading: [],
          },
        ]}
      />
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        detailsRTF={[
          {
            children: [
              {
                text: "An open‑pit gold miner had just completed a transition to owner‑mining, and faced many operational challenges including old mining equipment and a high‑maintenance processing plant.",
              },
            ],
            type: "heading-three",
          },
          {
            children: [
              {
                text: "Unit costs were under severe pressure: fixed costs increased by 100% following the transition; rising fuel and power costs; main pit getting deeper and haul distances longer.",
              },
            ],
            type: "paragraph",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "Survey data indicated insufficient digital readiness: approximately a quarter of members do not have nbn access and rural and remote Indigenous communities experience pronounced effects of reduced connectivity and reduced access to technology and digital skills, impeding access to quality healthcare. ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "A compelling business case was needed to secure the Federal government funding required to improve the connectivity and digital literacy for NACCHO members to facilitate telehealth options. ",
        //     heading: [],
        //   },
        // ]}
        heading={[
          {
            children: [
              {
                text: "35 volunteers, 105 hours served, contributing to $3,000 economic impact and over 500 individuals reached",
              },
            ],
            type: "paragraph",
          },
        ]}
        Articletitle="Impact achieved  "
      />
      {/* <ValuesCollection
        key=""
        title="Impact achieved "
        changeDesign="YES"
        styleclass="pt-7px"
        heading={[
          {
            children: [
              {
                text: "35 volunteers, 105 hours served, contributing to $3,000 economic impact and over 500 individuals reached ",
              },
            ],
            type: "paragraph",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "Current and future state planning – Prepared a view of current and desired future state with NACCHO and nbn (considering connectivity infrastructure and digital healthcare requirements) ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Development of prioritisation criteria – Developed framework for assessing which ACCHOs to prioritise for connectivity and digital capability uplift investment to ensure targeted value for money ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Connectivity and toolset requirements – Defined must have tools, services, connectivity improvements ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Cost benefit analysis – Summarised options for capability uplift, benefits and outcomes, and with breakdown of cost estimates ",
        //     heading: [],
        //   },
        // ]}
      /> */}
      <p className="col-span-12 col-start-2 text-center p-20px ga-mt--30">
        To learn more or to contribute to this great cause, please visit &nbsp;
        <b>
          <a
            href="https://projectcure.org/about-us/"
            className="text-dark-blue"
          >
            https://projectcure.org/about-us/
          </a>
        </b>
        and
        <b>
          &nbsp;
          <a href="https://www.hon.org/" className="text-dark-blue">
            https://www.hon.org/
          </a>
        </b>
      </p>
      <>
        <section className="py-section page-grid bg-white-1">
          <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
